<template>
  <v-container>
    <div class="content">
      <h1 class="text-h2 mb--1 font-weight-medium content-title">隐私政策与法律条款</h1>
      <v-divider class="divider"></v-divider>
      <div class="paragraph">
        <h3>使用条款</h3>
        <p>
          欢迎登录http://www.miqroera.com/home 网站！本网站为上海微观纪元数字科技有限公司（以下简称“微观纪元”）所有。在使用本网站或向本网站提供信息前，请务必阅读本网站隐私制度之全部内容。任何人进入、浏览和使用本网站即承认已阅读、理解并同意受本使用条款约束，并遵守相关的法律和法规。若您不同意遵从本使用条款，请勿使用本网站，微观纪元有权随时更新本使用条款，您将受到更新后的使用条款的约束，因此您应经常地访问并了解本网站的使用条款。
        </p>
      </div>
      <div class="paragraph">
        <h3>版权声明</h3>
        <p>
          本网站提供的任何内容（包括但不限于数据、文字、图表、图象、声音或视频等）的版权均属于微观纪元或相关权利人。未经微观纪元或相关权利人事先的书面许可，您不得以任何方式擅自复制、再造、传播、出版、转帖、改编或陈列本网站的内容。同时，未经微观纪元书面许可，对于本网站上的任何内容，任何人不得在非微观纪元所届的服务器上做镜像。任何未经授权使用本网站的行为都将违反《中华人民共和国著作权法》和其他法律法规的规定。
          本网站不保证为向用户提供便利而设置的外部链接的准确性和完整性，若由此而引起的版权问题或其他问题，请致电或电邮本网站。经核实后会立即予以删除或更改。
        </p>
      </div>
      <div class="paragraph">
        <h3>不担保声明</h3>
        <p>
          微观纪元不能保证本网站上任何内容的正确性、及时性、完整性和可靠性以及使用这些内容得出的结果。微观纪元以及其分支机构、子公司、董事、员工、代理以及其他任何代表（以下简称“相关人”）对于本网站内容的任何错误、不准确和遗漏以及使用本网站内容得出的结果都将不承担任何责任。
          微观纪元及相关人不能保证您任何时候均可进入、浏览和使用本网站，对本网站和其内容的不能使用和使用错误不承担任何责任。
          除注明之服务条款外，不承担其他一切因使用本网站或与本网站链接的任何其他网站或网页而引致之任何意外、疏忽、合约毁坏、诽谤、版权或知识产权侵犯及其所造成的损失（包括因下载而感染电脑病毒）。

        </p>
      </div>
      <div class="paragraph">
        <h3>内容改变</h3>
        <p>
          微观纪元有权随时改变和更新本网站上的内容，不需要先行通知您本人。
        </p>
      </div>
      <div class="paragraph">
        <h3>隐私保护</h3>
        <p>
        <ul>
          <li> 1.保护用户隐私是微观纪元的一项基本政策。微观纪元不会公布或传播您在本网站注册的任何资料，但下列情况除外：</li>
          <li>1）事先获得用户的明确授权；</li>
          <li>2）根据有关法律法规的要求；</li>
          <li>3）依据法院或仲裁机构的裁判或裁决，以及其他司法程序的要求；</li>
          <li>4）按照相关政府主管部门的要求；</li>
          <li>5）用户违反使用条款的规定或有其他损害微观纪元利益的行为；</li>
          <li>6）其他有关法律法规的要求。</li>
        </ul>
        </p>
      </div>
      <div class="paragraph">
        <p>
          2.本网站尊重并保护所有使用服务用户的个人隐私权。为了给您提供更准确、更有个性化的服务，本网站会按照本隐私权政策的规定使用和披露您的个人信息。 但本网站将以高度的勤勉、审慎义务对待这些信息。除本隐私权政策另有规定外，在未征得您事先许可的情况下，本网站不会将这些信息对外披露或向第三方提供。 本网站会不时更新本隐私权政策。您在同意本网站服务使用协议之时，即视为您已经同意本隐私权政策全部内容。本隐私权政策属于本网站服务使用协议不可分割的一部分。
        </p>
      </div>
      <div class="paragraph">
        <h3>免责声明</h3>
        <p>
          任何情况下，微观纪元及相关人对于进入或使用本网站引起的任何依赖本网站内容而作出的决定或采取的行动不承担任何责任，对进入或使用本网站而产生的任何直接的、间接的、惩罚性的损失或其他任何形式的损失包括但不限于业务中断、数据丢失或利润损失不承担任何责任，即使微观纪元被明确告知可能会发生上述损失。
          微观纪元以及相关人对于您在进入、浏览和使用本网站或从本网站下载任何内容而导致病毒或其他破坏性程序对您的电脑系统以及其他任何软件、硬件、 IT系统或财产的损害或损失不承担任何责任。
          微观纪元以及相关人对于第三人采用非法手段进入本网站取得有关密码、资料和内容而造成的任何损害或损失不承担任何责任。
        </p>
      </div>
      <div class="paragraph">
        <h3>法律适用和管辖</h3>
        <p>
          本网站及使用条款，应适用中华人民共和国法律。任何有关本网站和使用条款的争议，应由上海市浦东新区人民法院管辖。
        </p>
      </div>
    </div>
  </v-container>
</template>
<script>
export default {
};
</script>
<style lang="scss" scoped>
.content {
  color: #525252;
  font-size: 14px;
  font-family: "Source" "Han" "Sans" "CN-Medium";
  width: 80%;
  margin: 0 auto;
  .content-title {
    font-family: Source Han Sans CN-Regular, Source Han Sans CN !important;
    font-size: 30px !important;
    color: #525252;
    padding-left: 19px;
  }
  // 分割线
  .divider {
    border-color: #000000;
  }
  .paragraph {
    margin-top: 52px;
    padding-left: 19px;
    h3 {
      margin-bottom: 12px;
    }
    p {
      line-height: 33px;
    }
    ul,
    li {
      list-style: none;
      margin: 0;
      padding: 0;
    }
  }
}
</style>